import cookie from 'js-cookie'
import { Api6Error, AppNetworkError } from 'api/api.types'
import { ApiParameters } from 'api/api.constants'
import { Cookies } from 'constants/cookie'
import { NodeHeaders } from 'server/NodeHeaders'
import { paths as announcementOther } from 'api/generated/announcement_other'
import { paths as anonUsers } from 'api/generated/anon_users'
import { paths as auth } from 'api/generated/auth'
import { paths as billing } from 'api/generated/masked_billing'
import { paths as biometricVerification } from 'api/generated/biometric_verification'
import { buildFullQuery } from 'functions/queryString'
import { paths as captcha } from 'api/generated/captcha'
import { captureException } from '@sentry/browser'
import { checkAuthCookieApi } from './internal/checkAuthCookieApi'
import { consoleTime, consoleTimeEnd } from 'common/consoleLog'
import { paths as language } from 'api/generated/language'
import { paths as location } from 'api/generated/location'
import { paths as maskedAds } from 'api/generated/masked_ads'
import { paths as maskedReplies } from 'api/generated/masked_replies'
import { paths as masked_auth } from 'api/generated/masked_auth'
import { resolveDeviceId } from 'functions/mambaDeviceId'
import { resolveLocale } from 'api/api.functions'
import { paths as serviceProvision } from 'api/generated/service_provision'
import { sessionInitApi, sessionInitUri } from './system/sessionInitApi'
import { setTag } from '@sentry/core'
import { paths as user_owned_ads } from 'api/generated/masked_user_owned_ads'

const statusesWithoutJson = [204, 500]

export interface ApiData<T> {
  result?: T
  error?: Api6Error
  status?: number
}

export interface ApiResult<T> extends ApiData<T> {
  ok: boolean
  status: number
  headers: Headers
}

const createLocaleQueryMap = (options: RequestInit) => ({
  [ApiParameters.locale]: resolveLocale(
    options.headers as unknown as NodeHeaders
  ),
})

export type allPaths =
  | keyof announcementOther
  | keyof anonUsers
  | keyof location
  | keyof captcha
  | keyof auth
  | keyof language
  | keyof masked_auth
  | keyof serviceProvision
  | keyof billing
  | keyof maskedAds
  | keyof user_owned_ads
  | keyof biometricVerification
  | keyof maskedReplies

export const fetchQueryApi = async <T, Q>(
  url: allPaths,
  query: Q,
  options: RequestInit = {}
): Promise<ApiResult<T>> => {
  return callApi(
    (url +
      buildFullQuery({
        ...query,
        ...createLocaleQueryMap(options),
      })) as allPaths,
    options
  )
}

export const fetchApi = async <T>(
  url: allPaths,
  options: RequestInit = {}
): Promise<ApiResult<T>> => {
  const query = buildFullQuery(createLocaleQueryMap(options))

  return callApi((url + query) as allPaths, options)
}

export const postApi = async <T, B>(
  url: allPaths,
  body: B,
  options: RequestInit = {}
): Promise<ApiResult<T>> => {
  return bodyApi('POST', url, body, options)
}

export const putApi = async <T, B>(
  url: allPaths,
  body: B,
  options: RequestInit = {}
): Promise<ApiResult<T>> => {
  return bodyApi('PUT', url, body, options)
}

export const deleteApi = async <T, B>(
  url: allPaths,
  body: B,
  options: RequestInit = {}
): Promise<ApiResult<T>> => {
  return bodyApi('DELETE', url, body, options)
}

const bodyApi = async <T, B>(
  method: 'POST' | 'DELETE' | 'PUT',
  url: allPaths,
  body: B,
  options: RequestInit = {}
): Promise<ApiResult<T>> => {
  const query = buildFullQuery(createLocaleQueryMap(options))

  return callApi((url + query) as allPaths, {
    ...options,
    method,
    body: JSON.stringify(body),
  })
}

const callApi = async <T>(
  url: allPaths,
  options: RequestInit = {}
): Promise<ApiResult<T>> => {
  const timerName = `fetch API6 ${url} ${Math.round(1000 * Math.random())}`
  consoleTime(timerName)
  try {
    options.credentials = 'include'

    const headers = new Headers({
      'Content-Type': 'application/json; charset=utf-8',
      ...options.headers,
    })

    const browser = process.env.browser as unknown as boolean

    if (!browser) {
      if (!headers.get('User-Agent')) {
        // eslint-disable-next-line no-throw-literal
        throw new Error('Node header User-Agent should be defined!')
      }

      if (!headers.get('X-Forwarded-For')) {
        // eslint-disable-next-line no-throw-literal
        throw new Error('Node header X-Forwarded-For should be defined!')
      }
    }

    if (browser) {
      headers.set('Mamba-Device-Id', resolveDeviceId())
      const { accessToken } = window.getState().authorization

      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`)
      }
      if (options.method !== 'GET') {
        const sPost = cookie.get(Cookies.sPost)

        if (sPost) {
          headers.set('Csrf-Token', sPost)
        } else {
          captureException(new Error('Csrf-Token is empty'))

          if (!url.startsWith(sessionInitUri)) {
            // Очистим куку s_post, если она с флагом HttpOnly
            await checkAuthCookieApi()
            // Сделаем запрос, который засетит куку s_post
            await sessionInitApi()
          }

          const sPost = cookie.get(Cookies.sPost)

          if (sPost) {
            headers.set('Csrf-Token', sPost)
          } else {
            captureException(new Error('Csrf-Token is empty or not accessible'))
          }
        }
      }
    } else {
      // https://github.com/bitinn/node-fetch#fetch-options
      options.redirect = 'manual'

      // src/common/api/README.md
      if (options.method && options.method !== 'GET') {
        headers.set(
          'Csrf-Token',
          (options.headers as HeadersInit & { 'Csrf-Token': string })[
            'Csrf-Token'
          ]
        )
      }
    }

    options.headers = headers

    // console.log('process.env.API_6_HOST', process.env.API_6_HOST)
    const promise = fetch(process.env.API_6_HOST + url, options)
    const response = await promise

    const nodeName = response.headers.get('X-Mmb-Powered-By')

    if (nodeName) {
      setTag('node', nodeName)
    }

    if (statusesWithoutJson.includes(response.status)) {
      consoleTimeEnd(timerName)

      return {
        headers: response.headers,
        ok: response.ok,
        status: response.status,
      }
    }

    const json = await response.json()
    consoleTimeEnd(timerName)

    return {
      result: json,
      headers: response.headers,
      ok: response.ok,
      status: response.status,
    }
  } catch (error) {
    consoleTimeEnd(timerName)
    console.error(`API6 ${url}`, error)
    /**
     * https://www.totaltypescript.com/concepts/object-is-of-type-unknown#
     */
    throw new AppNetworkError((error as Error).message)
  }
}
